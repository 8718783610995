import { Controller } from 'stimulus'
import { Collapse } from 'bootstrap'

export default class extends Controller {
  static targets = ["collapse", "checkbox"]

  get checkboxActive() { return this.checkboxTarget.checked }

  get bsCollapse() {
    const { collapseTarget } = this

    return Collapse.getInstance(collapseTarget) || new Collapse(collapseTarget)
  }

  connect() {
  }

  update() {
    const collapse = this.bsCollapse

    if (this.checkboxActive) {
      collapse.show()
    } else{
      collapse.hide()
    }
  }
}
